// Horog.js
import React, { useEffect } from 'react';

import '../../css/index.css';
import ImageGalleryComponentOne from '../../components/ImageGalleryComponentOne';
import InformationContainerOne from '../../components/InformationContainerOne';


const updateMetaTags = (title, description, keywords) => {
  document.title = title;

  let metaDescription = document.querySelector('meta[name="description"]');
  if (metaDescription) {
    metaDescription.setAttribute("content", description);
  } else {
    metaDescription = document.createElement('meta');
    metaDescription.name = "description";
    metaDescription.content = description;
    document.head.appendChild(metaDescription);
  }

  let metaKeywords = document.querySelector('meta[name="keywords"]');
  if (metaKeywords) {
    metaKeywords.setAttribute("content", keywords);
  } else {
    metaKeywords = document.createElement('meta');
    metaKeywords.name = "keywords";
    metaKeywords.content = keywords;
    document.head.appendChild(metaKeywords);
  }
};

const Horog = () => {
  useEffect(() => {
    updateMetaTags(
      'Horog Pihenőház Mezőtúr',
      'Horog Pihenőház Mezőtúr',
      'Horog, Két Horog ,Pihenőház, Mezőtúr, horgász nyaraló, vízparti ház, családbarát pihenés, természet közeli nyaralás'
    );
  }, []);

  return (
    <div>
      <div className='container dekor'><br></br></div>
      <h1 className='cim p-3'>Horog Pihenőház</h1>
      <h2 className='cim'>Mezőtúr, Horog utca 117, 5400</h2>
      <ImageGalleryComponentOne />
      
      <div className="container section-row">
        <InformationContainerOne />
                 
      </div>
    </div>
  );
};

export default Horog;
